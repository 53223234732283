import _ from 'lodash'
import { t } from 'i18next';

export const snakeize = obj => _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.snakeCase(key);
    
    // Проверяем, является ли значение объектом, если да, рекурсивно вызываем snakeize
    // Если значение пустое (пустая строка, undefined или null), то выводим 'не указано'
    acc[camelKey] = _.isObject(value) ? snakeize(value) : (value === '' || value == null ? t('clientCard.notSpecified') : value);

  });

