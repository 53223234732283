import {
  Box,
  Grid,
  Drawer,
  Button,
  Typography,
} from '@mui/material';
// import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import { t } from 'i18next';

const ClientModal = ({ open, data, onCollapse }) => {
  if (!data) return null;

  const { task_id, campaign_id, additional_fields = {} } = data;

  return (
    <Drawer
      anchor="right"
      onClose={onCollapse} // Закрываем при клике на свободную область
      open={open}
      PaperProps={{
        sx: {
          width: "50vw", // Половина ширины экрана
        },
      }}
    >
      {
        task_id ?
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom
              sx={{ mb: 3, textAlign: 'center' }}
            >
              <strong> {t('clientCard.title')}
              </strong>
            </Typography>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  <strong>Account Number:</strong> {additional_fields["account_number"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Client Name:</strong> {additional_fields["client_name"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Primary Contact Number 1:</strong> {additional_fields["primary_contact_number_1"]}
                  {/* <div>International: {value && formatPhoneNumberIntl(value)}</div> */}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Total Debt:</strong> {additional_fields["total_debt"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Task ID:</strong> {task_id}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Campaign ID:</strong> {campaign_id}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  <strong>Other Contact Number 1:</strong> {additional_fields["other_contact_number_1"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Other Contact Name 1:</strong> {additional_fields["other_contact_name_1"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Other Contact Number 2:</strong> {additional_fields["other_contact_number_2"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Other Contact Name 2:</strong> {additional_fields["other_contact_name_2"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>DPD:</strong> {additional_fields["dpd"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Email:</strong> {additional_fields["email"]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  <strong>Lifetime ID:</strong> {additional_fields["lifetime_id"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Bucket ID:</strong> {additional_fields["bucket_id"]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  <strong>Agent:</strong> {additional_fields["agent"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Status Contract:</strong> {additional_fields["status_contract"]}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Comment:</strong> {additional_fields["comment"]}
                </Typography>
              </Grid>
            </Grid>
            <Button onClick={onCollapse} color="primary" variant="contained" sx={{ mt: 3, textAlign: 'right' }}>
              {t('collapse')}
            </Button>
          </Box>
          :
          t('clientCard.noData')
      }
    </Drawer>
  );
};

export default ClientModal;