export const ASTERISK_HOST = getEnvVariable("REACT_APP_ASTERISK_HOST")
export const HANGUP_SIGN = getEnvVariable("REACT_APP_HANGUP_SIGN")

export function getEnvVariable(name) {
  const valueFromWindow = window[name]
  if (valueFromWindow && valueFromWindow !== "" && !/\$\{/.test(valueFromWindow)) {
    return valueFromWindow
  } else {
    return process.env[name]
  }
}
