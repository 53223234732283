import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Автоопределение языка
  .use(initReactI18next) // Подключение к React
  .init({
    resources: {
      en: {
        translation: {
          operator: "Operator:",
          inConversation: "In conversation:",
          waitTask: "Waiting for task",
          expand: "Expand",
          collapse: "Collapse",
          goOnline: "Go online",
          goOffline: "Go offline",
          online: 'Online',
          warn: "Are you sure?",
          warnDescription: "The user will go offline. Are you sure you want to log out?",
          cancel: "Cancel",
          unmute: "Unmute",
          mute: "Mute",
          signIn: "Sign In",
          signInButton: "Sign In",
          signInError: "Connection error",
          fillInputs: "Please fill in all fields",
          logout: "Logout",
          hangup: "Hang up",
          clientCard: {
            title: "Client Card",
            taskId: "Task ID:",
            campaignId: "Campaign ID:",
            notSpecified: 'N/A',
            noData: "Failed to load data or data is missing"
          }
        }
      },
      ru: {
        translation: {
          operator: 'Оператор:',
          inConversation: 'В разговоре:',
          waitTask: 'Ожидает задачи',
          expand: 'Открыть',
          collapse: 'Свернуть',
          goOnline: 'Выйти на линию',
          goOffline: 'Уйти с линии',
          online: 'На линии',
          warn: 'Вы уверены?',
          warnDescription: 'Пользователь уйдет с линии. Вы уверены, что хотите выйти?',
          cancel: 'Отмена',
          unmute: 'Включить микрофон',
          mute: 'Выключить микрофон',
          signIn: 'Вход',
          signInButton: 'Войти',
          signInError: 'Ошибка подключения',
          fillInputs: 'Пожалуйста, заполните все поля',
          logout: 'Выйти',
          hangup: 'Отбой',
          clientCard: {
            title: 'Карточка клиента',
            taskId: 'ID задачи:',
            campaignId: 'ID кампании:',
            notSpecified: 'Не указано',
            noData:'Не получилось загрузить данные или данные отсутствуют'
          },
        },
      },
    },
    fallbackLng: 'en', // Язык по умолчанию
    interpolation: {
      escapeValue: false, // React сам экранирует строки
    },
  });

export default i18n;