import JsSIP from 'jssip';
import { ASTERISK_HOST, HANGUP_SIGN } from '../env';

const SipService = {
  ua: null,
  currentSession: null,
  remoteAudioElement: null,
  messageHandlers: [], // Массив для хранения обработчиков сообщений

  connect({ login, password }) {
    return new Promise((resolve) => {
      const socket = new JsSIP.WebSocketInterface(`wss://${ASTERISK_HOST}:8089/ws`);
      const configuration = {
        sockets: [socket],
        uri: `sip:${login}@${ASTERISK_HOST}`,
        password,
      };

      this.ua = new JsSIP.UA(configuration);

      this.ua.on('registered', () => resolve(true));
      this.ua.on('registrationFailed', () => resolve(false));

      this.ua.on('newRTCSession', (e) => {
        console.log('New RTC Session:', e);
        const session = e.session;

        // Сохраняем сессию
        this.currentSession = session;

        // Обработка входящих вызовов
        if (session.direction === 'incoming') {
          console.log('Incoming call:', session);
          session.answer({
            mediaConstraints: { audio: true, video: false },
          });
        }

        session.on('confirmed', () => {
          console.log('Call confirmed');
        });

        session.on('ended', () => {
          console.log('Call ended');
          this.currentSession = null;
        });

        session.on('failed', () => {
          console.log('Call failed');
          this.currentSession = null;
        });
        
        // // Назначение потока
        // session.connection.addEventListener('track', (event) => {
        //   if (this.remoteAudioElement) {
        //     this.setStream(event.streams[0]);
        //   }
        // });
      });


      SipService.ua.on('newMessage', (event) => {
        // const data = JSON.parse(event.request.body);


        console.log('сообщение', event.request.body)
        try {
          const data = JSON.parse(event.request.body);
  
          // Проверяем, содержит ли сообщение необходимые данные
          if (data?.task_id) {
            // Здесь можно вызвать колбек или выполнить другое действие с данными
            // Например, можно вызвать callback(data), если он передан как параметр
            SipService.messageHandlers.forEach(handler => handler(data)); // Вызываем всех подписчиков
          }
        } catch (error) {
          console.error('Ошибка обработки сообщения:', error);
        }


        // SipService.messageHandlers.forEach(handler => handler(data)); // Вызываем всех подписчиков
      });


      this.ua.start();

      console.log('this.ua', this.ua)
    });
  },

  onNewMessage: (handler) => {
    SipService.messageHandlers.push(handler); // Добавляем обработчик
  },




  onNewMessage2(callback) {
    if (this.ua) {
      this.ua.on('newMessage', (event) => {
        try {
          const body = event.request.body;
  
          if (!body) {
            console.warn('Получено пустое сообщение');
            return;
          }
  
          const data = JSON.parse(body);
  
          // Проверяем наличие task_id
          if (data?.task_id) {
            callback(data); // Передаем данные в callback
          }
        } catch (error) {
          console.error('Ошибка обработки newMessage:', error.message);
        }
      });
    }
  },



  setStream(stream) {
    if (this.remoteAudioElement) {
      this.remoteAudioElement.srcObject = stream;
      this.remoteAudioElement.play().catch((error) => {
        console.error('Failed to play the audio stream:', error);
      });
    }
  },

  setAudioElement(audioElement) {
    this.remoteAudioElement = audioElement;
  },

  checkConnectionStatus() {
    return Promise.resolve(this.ua?.isConnected());
  },

  goOnline() {
    return new Promise((resolve) => {
      if (this.ua) {
        // this.ua.register();
        resolve(true);
      } else {
        resolve(false);
      }
    });
  },

  goOffline() {
    if (this.ua) {
      this.dtmf(HANGUP_SIGN)
      this.ua.terminateSessions();
    }
  },
  
  goLogout() {
    if (this.ua) {
      this.ua.stop();
      this.ua.unregister();
    }
  },



  makeCall(number) {
    if (this.ua && this.ua.isRegistered()) {
      const eventHandlers = {
        progress: (e) => {
            console.log('Call in progress', e)
            return
        },
        failed: (e) => {
            console.log('Call failed:', e);
        },
        ended: (e) => {
            console.log('Call ended', e);
        },
        confirmed: (e) => {
            console.log('Call confirmed', e)
            return
        },
    };


      const options = {
        eventHandlers,
        mediaConstraints: { audio: true, video: false },
        rtcOfferConstraints: {
            offerToReceiveAudio: true,
            offerToReceiveVideo: false,
        }
      };
        
      const session = this.ua.call(`sip:${number}@${ASTERISK_HOST}`, options);
      this.currentSession = session;
      // Назначение потока
      this.currentSession.connection.addEventListener('track', (event) => {
        if (this.remoteAudioElement) {
          this.setStream(event.streams[0]);
        }
      });

      session.on('ended', () => {
        console.log('Outgoing call ended');
        this.currentSession = null;
      });

      session.on('failed', () => {
        console.log('Outgoing call failed');
        this.currentSession = null;
      });

    } else {
      console.log('UA is not registered or available.');
    }
  },

  hangupCall() {
    if (this.currentSession) {
      this.currentSession.terminate();
      this.currentSession = null;
    }
  },

  dtmf(tone) {
    if (this.currentSession) {
      console.log('hangup success', tone)
    this.currentSession?.sendDTMF(tone);
    }
  }
};

export default SipService;